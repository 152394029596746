<template>
  <div class="not-found">
    <main class="content-width-sm">
      <h1 class="number">404</h1>
      <p class="message">De pagina waar je naar zocht kon niet worden gevonden</p>

      <BaseButton
        component="router-link"
        icon-right="arrow-right"
        to="/"
      >
        Ga naar homepage
      </BaseButton>
    </main>
  </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton'

export default {
  components: {
    BaseButton
  },
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/mixins/utils' as *;
@use '~@/assets/styles/variables/colors' as *;

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo {
  margin-bottom: 40px;
}

main {
  margin: auto;
}

.number {
  @include fluid-type(80px, 160px);
  color: $green;
}

.message {
  @include fluid-type(16px, 18px);
  font-weight: bold;
  margin-bottom: 40px;
}
</style>
