<template functional>
  <svg
    class="icon"
    :class="data.staticClass"
    :style="data.style"
    v-bind="data.attrs"
    v-on="data.on"
  >
    <use :xlink:href="`#${$options.iconSymbols[props.name].id}`"/>
  </svg>
</template>

<script>
// Using require.context() to load all icons in @/assets/icons/ using svg-sprite-loader and svgo-loader
// Inline loaders and file path in single string because it needs to be statically analyzable.
const iconRequireContext = require.context(
  '!svg-sprite-loader?{"symbolId":"icon-[name]"}!svgo-loader?{"plugins":[{"convertColors":{"currentColor":true}}]}!@/assets/icons/',
  false,
  /.svg$/
);

// Create an object containing all icon names and the corresponding symbol
const iconSymbols = iconRequireContext.keys().reduce((symbols, fileName) => {
  const iconName = fileName.replace(/\.\/(.+)\.svg/, '$1');
  const module = iconRequireContext(fileName).default;

  return {
    ...symbols,
    [iconName]: module
  };
}, {});

export default {
  iconSymbols,
  props: {
    name: {
      type: String,
      required: true,
      validator: value => {
        const iconExists = Object.keys(iconSymbols).includes(value);

        // Show an error when the provided name does not exist in the iconSymbols object
        if (!iconExists) console.error(`No icon with name ${value} exists`);

        return iconExists;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  font-size: 1.5em;
  width: 1em;
  height: 1em;
}
</style>
