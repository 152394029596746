<template>
  <figure class="image-gallery">
    <figure v-for="(item, i) in items" :key="i" class="item">
      <img class="item-image" :src="require(`@/${item.image}`)" :alt="item.imageAlt" width="320" height="180">

      <div class="item-label">
        <BaseIcon :name="item.icon" class="item-icon"/>
        <figcaption>{{ item.title }}</figcaption>
      </div>
    </figure>
  </figure>
</template>

<script>
import BaseIcon from '../ui/BaseIcon.vue'
export default {
  components: { BaseIcon },
  props: {
    items: {
      type: Array,
      required: true,
      validator: items => items.every(item => typeof item.image === 'string')
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/variables/colors' as *;
@use '~@/assets/styles/mixins/utils' as *;

.image-gallery {
  display: flex;
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 16px;
  margin: 0;
}

.item {
  position: relative;
  display: block;
  margin: 0;
  flex: 1;
  min-width: 0;
  overflow: hidden;
  color: $white;
}

.item-image {
  @include overlay();
  object-fit: cover;
  filter: grayscale(1) brightness(85%);
  transition: all 0.25s ease;

  .item:hover & {
    transform: scale(1.05);
    filter: none;
  }
}

.item-label {
  @include overlay;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  filter: drop-shadow(0 0 8px rgba(#000, 0.75));
  background: linear-gradient(to top, rgba(#000, 0.25), transparent);
}

::v-deep .item-icon {
  color: inherit;
  height: 48px;
  width: 120px;
  margin-bottom: 8px;
  transition: all 0.25s ease;

  .item:hover & {
    transform: translateY(-8px);
    filter: none;
  }
}
</style>
