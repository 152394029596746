<template>
  <fieldset class="question-sub-item">
    <legend class="title">{{ title }}</legend>

    <ul class="option-container">
      <li class="option" v-for="option in options" :key="option.value">
        <QuestionRadio
          :value="option.value"
          :label="option.label"
          :modelValue="value"
          @change="e => $emit('change', option.value)"
        />
      </li>
    </ul>
  </fieldset>
</template>

<script>
import QuestionRadio from '@/components/questions/QuestionRadio';

export default {
  components: {
    QuestionRadio
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: String,
    options: {
      type: Array,
      required: true,
      validator: value => {
        return value.every(item => (
          item.label && typeof item.label === 'string'
          && item.value && typeof item.value === 'string'
        ));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/variables/colors' as *;
@use '~@/assets/styles/mixins/media-queries' as media;

.question-sub-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  margin: 0;
  padding: 12px 0 12px 24px;


  @include media.min('tablet') {
    &:nth-child(odd) {
      background: $gray-1;
    }
  }

  @include media.max('tablet') {
    flex-direction: column;
    align-items: flex-start;
    background: $gray-1;
    margin-bottom: 16px;
    padding: 24px;
    border-radius: 8px;
  }
}

.title {
  float: left;
  color: $blue;
  font-weight: bold;
  max-width: 576px;
}

.option-container {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;

  @include media.max('tablet') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 16px;
    padding: 0;
    width: 100%;
    align-items: flex-start;
  }
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;

  @include media.max('tablet') {
    justify-content: flex-start;
    width: auto;
  }
}
</style>
