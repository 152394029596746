<template>
  <article class="survey-result">
    <h1>Hey {{ $store.state.name }}!</h1>

    <section
      class="result-description"
      :style="{ backgroundImage: `url(${require(`@/assets/images/result-illustrations/${result.illustration}.svg`)}` }"
    >
      <h2 class="result-title">{{ result.copy.introduction.title }}</h2>
      <p>{{ result.copy.introduction.body }}</p>
    </section>

    <BaseButton
      class="result-download-button"
      icon-right="download"
      @click="downloadResult"
      :disabled="downloadingResult"
    >
      download volledige uitslag
    </BaseButton>

    <SurveyResultPDF ref="pdfTemplate"/>
  </article>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton';

export default {
  components: {
    BaseButton,
    SurveyResultPDF: () => import('@/components/survey/SurveyResultPDF')
  },
  data() {
    return {
      downloadingResult: false
    }
  },
  computed: {
    result() {
      return this.$store.getters.result
    }
  },
  methods: {
    async downloadResult() {
      this.downloadingResult = true;

      await this.$refs.pdfTemplate.download('uitslag-stagetoer.pdf');

      this.downloadingResult = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/variables/colors' as *;
@use '~@/assets/styles/mixins/utils' as *;
@use '~@/assets/styles/mixins/media-queries' as media;

.survey-result {
  text-align: center;
  padding-bottom: 24px;

  @include media.max('tablet') {
    text-align: left;
    padding-bottom: 0;
  }
}

.result-description {
  position: relative;
  border-radius: 16px;
  padding: 64px 40px;
  max-width: 800px;
  margin: 0 auto;
  background-color: $gray-1;
  background-repeat: no-repeat;
  background-position: bottom -80px right -32px;
  background-size: 400px;

  @include media.max('mobile-l') {
    padding: 24px;
    margin-bottom: 16px;
  }
}

.result-title {
  color: $green;
}

.result-download-button {
  @include position-center('x');
  position: absolute;
  bottom: 0;

  @include media.max('mobile-l') {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100%;
  }
}
</style>
