/**
 * Returns a number whose value is limited to the given range.
 * @param {Number} number The number to clamp.
 * @param {Number} min The lower boundary of the output range.
 * @param {Number} max The upper boundary of the output range.
 * @returns {Number} A number in the range [min, max]
 */
const clamp = (number, min, max)  => Math.min(Math.max(number, min), max);

/**
 * Returns the key of the property with the highest value in an object.
 * @param {Object} obj The object from which to get the property.
 * @returns {String} The key of the property with the highest value.
 */
const getPropertyWithHighestValue = obj => {
  return Object.keys(obj).reduce((a, b) => obj[a] > obj[b] ? a : b, '');
}

export {
  clamp,
  getPropertyWithHighestValue
}
