<template>
  <label class="question-radio">
    <input
      class="input"
      type="radio"
      :value="value"
      :checked="modelValue === value"
      @change="e => $emit('change', value)"
    >

    <span class="control"></span>
    <span class="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    modelValue: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/mixins/utils' as *;
@use '~@/assets/styles/variables/colors' as *;
@use '~@/assets/styles/mixins/media-queries' as media;

.question-radio {
  cursor: pointer;

  @include media.max('tablet') {
    display: flex;
    align-items: center;
  }
}

.input {
  @include visually-hidden();
}

.label {
  @include media.min('tablet') {
    @include visually-hidden();
  }

  @include media.max('tablet') {
    margin-left: 16px;
  }

  @include media.max('mobile-l') {
    font-size: 14px;
  }
}

.control {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid rgba($blue, 0.5);
  background: $white;
  flex-shrink: 0;
  transition: all 0.12s ease;

  &::after {
    @include position-center();
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $blue;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.12s ease;
  }
}

.input:checked + .control {
  border-color: $blue;

  &::after {
    transform: translate(-50%, -50%) scale(1);
  }
}

.input:hover + .control {
  border-color: $blue;
  background: rgba($blue, 0.1)
}
</style>
