<template>
  <div class="home">
    <main class="content-width-sm">
      <img
        src="@/assets/images/logo.svg"
        alt="Stagetoer logo"
        class="logo"
        width="160"
        height="160"
      >

      <h1>Welkom bij StageToer</h1>
      <p>Jij gaat binnenkort stagelopen. Je zit wellicht al te denken aan een stageplaats in Nederland…. Maar hoe mooi zou het zijn als je tijdens je stage ook iets meekrijgt van een andere taal en cultuur? Er zijn ook voor jou meerdere mogelijkheden.</p>
      <p>Ben je heel avontuurlijk en wil je het liefste zo ver en lang mogelijk weg, of blijf je liever dicht bij huis voor een stage? En wist je dat je ook binnen Nederland of vlak over de grens hele leuke stages kunt lopen bij internationale bedrijven? Doe snel de test en kijk wat bij jou past.</p>

      <div class="name-input-container">
        <TextInput
          class="name-input"
          label="vul je naam in…*"
          v-model.lazy="name"
          :hasError="startButtonClicked && !name"
        />

        <BaseButton
          class="start-button"
          icon-right="arrow-right"
          @click="onStartButtonClick"
        >
          start mijn toer
        </BaseButton>
      </div>

      <p v-if="startButtonClicked && !name" class="name-input-error">Je moet je naam invullen om door te gaan</p>
      <p class="small-text name-input-notice">Deze toer zal niet langer dan 15 minuten duren en gegevens worden niet opgeslagen</p>
    </main>

    <section class="content-width-lg image-gallery-container">
      <h2 class="h3 image-gallery-title">Hier zou je kunnen stagetoeren:</h2>
      <ImageGallery :items="imageGalleryItems"/>
    </section>
  </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton';
import TextInput from '@/components/ui/TextInput.vue';
import ImageGallery from '@/components/home/ImageGallery';
import imageGalleryItems from '@/assets/data/image-gallery-items';

export default {
  name: 'Home',
  components: {
    BaseButton,
    TextInput,
    ImageGallery
  },
  data() {
    return {
      startButtonClicked: false,
      name: this.$store.state.name, // Hyadrate with name from store
      imageGalleryItems
    }
  },
  methods: {
    onStartButtonClick() {
      this.startButtonClicked = true;

      if (this.name) {
        this.$store.commit('setActiveStep', 0); // Start on first servey step
        this.$store.commit('clearAnswers'); // Clear all previous answers in the store
        this.$store.commit('setName', this.name); // Set name for result overview
        this.$router.push('test');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/variables/colors' as *;
@use '~@/assets/styles/mixins/media-queries' as media;

$name-input-breakpoint: 500px;

.home {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include media.max('laptop') {
    align-items: center;
  }
}

.logo {
  display: block;
  width: 160px;
  height: auto;
  margin: 0 auto 48px auto;

  @include media.max('mobile-l') {
    width: 120px;
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
}

.name-input-container {
  display: flex;
  align-items: center;
  margin-top: 32px;
  text-align: center;

  @include media.max($name-input-breakpoint) {
    width: 100%;
    flex-direction: column;
  }
}

.name-input {
  padding-right: 40px;
  margin-right: -40px;
  width: 100%;

  @include media.max($name-input-breakpoint) {
    padding: 0;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.name-input-error {
  color: $red;
  margin-top: 8px;
}

.start-button {
  flex-shrink: 0;
  z-index: 1;

  @include media.max($name-input-breakpoint) {
    width: 100%;
  }
}

.name-input-notice {
  max-width: 320px;
  margin-top: 16px;
  text-align: center;
}

.image-gallery-container {
  width: 100%;
  text-align: center;
  margin-top: auto;
  padding-top: 40px;

  @include media.max('laptop') {
    display: none;
  }
}

.image-gallery-title {
  color: $pink;
}
</style>
