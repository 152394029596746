<template>
  <component
    :is="component"
    class="button"
    :class="[
      `variant-${variant}`,
      { 'is-disabled': disabled }
    ]"
    v-on="$listeners"
    v-bind="$attrs"
    :disabled="disabled"
  >
    <BaseIcon class="icon-left" v-if="iconLeft" :name="iconLeft"/>
    <slot/>
    <BaseIcon class="icon-right" v-if="iconRight" :name="iconRight"/>
  </component>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon';

export default {
  components: {
    BaseIcon
  },
  props: {
    component: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: 'solid',
      validator: value => ['solid', 'text'].includes(value)
    },
    iconLeft: String,
    iconRight: String,
    disabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/variables/colors' as *;
@use 'sass:color';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 999px;
  border: none;
  padding: 0 32px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  transition: background-color 0.12s ease;
  text-decoration: none;

  &.variant-solid {
    background: $blue;
    color: $white;

    &:hover {
      background: color.scale($blue, $lightness: 10%);
    }

    &:active {
      background: color.scale($blue, $lightness: -20%);
    }
  }

  &.variant-text {
    background: none;
    color: $blue;

    &:hover {
      background: rgba($blue, 0.1)
    }

    &:active {
      background: rgba($blue, 0.2)
    }
  }

  &.is-disabled {
    background: $gray-2;
    color: rgba(#000, 0.25);
    cursor: not-allowed;

    &:hover {
      background: $gray-2;
    }

    // Disable links
    &:active {
      pointer-events: none;
    }
  }
}

::v-deep .icon-left {
  line-height: 0;
  margin: 0 8px 0 -8px;
}

::v-deep .icon-right {
  line-height: 0;
  margin: 0 -8px 0 8px;
}
</style>
