import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import cloneDeep from 'lodash.clonedeep';
import questions from '@/assets/data/questions';
import results from '@/assets/data/results';
import { getPropertyWithHighestValue } from '@/assets/scripts/utils';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: '',
    questions,
    answers: questions.map(() => []), // Hydrate 2 dimensional answer array
    activeStep: 0
  },
  mutations: {
    // Set name value
    setName(state, newName) {
      state.name = newName;
    },

    // Update the answers for a question sub item
    setSubitemAnswer(state, { questionIndex, subitemIndex, answer }) {
      // Create a deep clone to update the whole answers array. This way Vuex will detect the change.
      const newAnswers = cloneDeep(state.answers);

      newAnswers[questionIndex][subitemIndex] = answer;

      state.answers = newAnswers;
    },

    // Clear all question answers
    clearAnswers(state) {
      state.answers = questions.map(() => []);
    },

    // Set the active survey step
    setActiveStep(state, newActiveStep) {
      state.activeStep = newActiveStep;
    }
  },
  getters: {
    // Get all answers with the corresponding category as a flat array
    answersWithCategories: state => {
      return state.answers.reduce((flattenedAnswers, questionAnswers, questionIndex) => [
        ...flattenedAnswers,
        ...questionAnswers
          .filter(answer => Boolean(answer)) // Filter out empty values
          .reduce((answers, answer, subitemIndex) => [
            ...answers,
            {
              answer,
              category: state.questions[questionIndex].subitems[subitemIndex].category
            }
          ], [])
      ], []);
    },

    // Get the scores for the answer categories of the answered questions
    scores: (_, getters) => {
      return getters.answersWithCategories.reduce((scores, { category, answer }) => ({
        ...scores,
        [category]: (scores[category] || 0) + Number(answer)
      }), {});
    },

    // Get the result data for the current score
    result: (_, getters) => {
      // Get the category with the highest score as the result category
      const resultCategory = getPropertyWithHighestValue(getters.scores);
      const defaultResult = Object.values(results)[0];

      return results[resultCategory] || defaultResult;
    }
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      key: 'stagetoer_store',
      paths: ['name', 'answers', 'activeStep']
    })
  ]
})
