<template>
  <div class="stepper-steps">
    <div class="step-icon-container">
    <BaseIcon
      name="bus"
      class="step-icon"
      :style="{ left: `${100 * this.activeStep / (this.steps - 1)}%` }"
    />
    </div>

    <div class="step-container">
      <div
        class="step"
        v-for="(step, i) in steps"
        :key="step"
        :class="{
          'is-active': i === activeStep,
          'is-finished': i < activeStep
        }"
      >
        <div class="step-connector" v-if="i != 0"></div>
        <div class="step-marker">
          <BaseIcon v-if="i === steps - 1" name="flag"/>
          <BaseIcon v-else-if="i < activeStep" name="check"/>
          <template v-else>{{ step }}</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon';

export default {
  components: {
    BaseIcon
  },
  props: {
    steps: {
      type: Number,
      required: true
    },
    activeStep: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/variables/colors' as *;
@use '~@/assets/styles/variables/typography' as *;
@use '~@/assets/styles/mixins/utils' as *;

$step-size-ratio: 0.625; // Ratio between active/inactive steps
$step-font-size-min: 20px;
$step-font-size-max: 32px;

.stepper-steps {
  @include fluid-type($step-font-size-min, $step-font-size-max, 320px, 768px);
}

.step-container {
  display: flex;
  margin: 0 #{$step-size-ratio * -0.5em};
}

.step {
  flex: 1;
  display: flex;
  align-items: center;

  &:first-child {
    flex: 0;
  }
}

.step-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  background: $green;
  color: $white;
  font-family: $font-family-title;
  border-radius: 999px;
  flex-shrink: 0;
  transform: scale($step-size-ratio) translateZ(0);
  z-index: 1;
  transition:
    background-color 0.25s ease,
    transform 0.25s ease;

  .step.is-active &,
  .step.is-finished & {
    background: $blue;
  }

  .step.is-active & {
    transform: scale(1) translateZ(0);
  }

  ::v-deep .icon {
    font-size: 1.25em;
  }
}

.step-connector {
  position: relative;
  height: 8px;
  width: calc(100% + 30px);
  background: $green;
  margin: 0 #{$step-size-ratio * -1em}; // Negative margin to fill gaps created by .step-number transform

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    transform-origin: left;
    transform: scaleX(0) translateZ(0);
    transition: transform 0.25s ease;

    .step.is-active &,
    .step.is-finished & {
      transform: scaleX(1) translateZ(0);
    }
  }
}

// Container spanning width from center of first item to center of last item
.step-icon-container {
  position: relative;
  width: calc(100% - #{$step-size-ratio * 1em * 2});
  height: 1.25em;
  margin: 0 auto 8px auto;
}

::v-deep .step-icon {
  bottom: calc(100% + 8px);
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-50%);
  font-size: 1em;
  height: 100%;
  width: 3em;
  color: $pink;
  transition: left 0.5s cubic-bezier(0.5, 0.1, 0.28, 1.17);
}
</style>
