
<template>
  <div class="pdf-generator" v-if="generating">
    <slot/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      generating: false
    }
  },
  props: {
    format: [String, Array],
    orientation: {
      type: String,
      validator: value => ['portrait', 'landscape'].includes(value)
    },
    fontFaces: {
      type: Array,
      default: () => [],
      validator: value => value.every(fontFace => (
        Object.hasOwnProperty.call(fontFace, 'family')
        && Object.hasOwnProperty.call(fontFace, 'src')
        && Object.hasOwnProperty.call(fontFace, 'weight')
      ))
    }
  },
  methods: {
    async generate() {
      // Set generating state to add the PDF content to the DOM
      this.generating = true;

      // Load jsPDF only when generating PDF.
      const { default: jsPDF } = await import('jspdf');

      // Wait for content to be rendered
      await this.$nextTick();

      // Create PDF  document
      const pdfDocument = new jsPDF({
        unit: 'px',
        hotfixes: ['px_scaling'], // px_scaling fixes output scaling
        format: this.format,
        orientation: this.orientation
      });

      // Load custom fonts
      this.fontFaces.forEach(fontFace => {
        pdfDocument.addFont(fontFace.src, fontFace.family, fontFace.weight);
      });

      // Convert first element in the default slot to PDF
      await pdfDocument.html(this.$slots.default[0].elm, { fontFaces: undefined });

      // Set generating state to remove the pdf content from the DOM
      this.generating = false;

      return pdfDocument;
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf-generator {
  height: 0;
  visibility: hidden;
}
</style>
