<template>
  <transition-group :name="transitionName" class="stepper-items" tag="div">
    <div
      class="stepper-item"
      v-for="(item, i) in items"
      :key="item.key"
      v-show="i === activeStep"
    >
      <component
        :is="item.component"
        v-bind="item.attributes"
        v-on="item.listeners"
      >
        {{ item.innerText }}
      </component>
    </div>
  </transition-group>
</template>

<script>
export default {
  props: {
    activeStep: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true,
      validator: value => value.every(item => (
        Object.hasOwnProperty.call(item, 'key')
        && Object.hasOwnProperty.call(item, 'component')
      ))
    }
  },
  data() {
    return {
      transitionName: 'slide-left'
    }
  },
  watch: {
    // Change the transition name depending on direction of the item
    activeStep(newActiveStep, oldActiveStep) {
      this.transitionName = newActiveStep > oldActiveStep ? 'slide-left' : 'slide-right'
    }
  }
}
</script>

<style lang="scss" scoped>
.stepper-items {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.stepper-item {
  top: 0;
  left: 0;
  width: 100%;
}

.slide-left-leave-active,
.slide-right-leave-active {
  position: absolute;
}

.slide-left-enter-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-right-leave-active {
  transition:
    transform 0.4s ease,
    opacity 0.4s ease;
}

.slide-left-enter-to,
.slide-right-enter-to {
  transform: translate3d(0, 0, 0);
}

.slide-left-enter,
.slide-right-leave-to {
  transform: translate3d(50%, 0, 0);
  opacity: 0;
}

.slide-right-enter,
.slide-left-leave-to {
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
}
</style>
