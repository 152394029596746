<template>
  <div
    class="text-input"
    :class="{
      'is-focused': isFocused,
      'has-error': hasError
    }"
  >
    <label
      :for="`textInput-${uid}`"
      :class="{ 'is-floating': isFocused || value }"
    >
      {{ label }}
    </label>

    <input
      type="text"
      :id="`textInput-${uid}`"
      :value="value"
      @focus="onFocus"
      @blur="onBlur"
      @input="e => $emit('input', e.target.value)"
    >
  </div>
</template>

<script>
import componentUID from '@/mixins/component-uid';

export default {
  mixins: [componentUID],
  props: {
    label: String,
    value: String,
    hasError: Boolean
  },
  data() {
    return {
      isFocused: false
    }
  },
  methods: {
    onFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    onBlur() {
      this.isFocused = false;
      this.$emit('blur');
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/variables/colors' as *;
@use '~@/assets/styles/mixins/utils' as *;

.text-input {
  display: flex;
  align-self: center;
  position: relative;
  height: 48px;
  border-radius: 999px;
  background: $gray-1;
  transition: box-shadow 0.25s ease;

  &:hover {
    box-shadow: inset 0 0 0 2px rgba(#000, 0.125);
  }

  &.is-focused {
    box-shadow: inset 0 0 0 2px $blue;
  }

  &.has-error {
    box-shadow: inset 0 0 0 2px $red;
  }
}

input {
  border: none;
  background: none;
  outline: none;
  padding: 0 24px;
}

label {
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  transform-origin: left;
  pointer-events: none;
  color: rgba(#000, 0.65);
  font-weight: bold;
  transition: all 0.25s ease;

  &.is-floating {
    color: $blue;
    transform: translateY(calc(-50% - 36px)) scale(0.8);
  }

  .has-error & {
    color: $red;
  }
}
</style>
