<template>
  <PDFGenerator ref="pdfGenerator" :font-faces="fontFaces">
    <article class="template">
      <div class="content-container">
        <div class="background-image-container">
          <img
            class="background-image"
            :src="require(`@/assets/images/result-pdf/illustrations/${result.illustration}.png`)"
            alt="achtergrond illustratie"
          >
        </div>

        <img class="logo-top" src="@/assets/images/result-pdf/logos/logo_stagetoer.png" alt="logo Stagetoer">

        <header>
          <h1 class="title">
            Hey <span class="text-highlight">{{ $store.state.name }}!</span><br>
            Dit is de uitslag van je toer!
          </h1>
        </header>

        <section class="body-section">
          <h2>{{ result.copy.introduction.title }}</h2>
          <p>{{ result.copy.introduction.body }}</p>
        </section>

        <section class="body-section">
          <h2 class="text-highlight">{{ result.copy.description.title }}</h2>

          <section v-for="(section, i) in result.copy.description.body" :key="i">
            <h3 v-if="section.title">{{ section.title }}</h3>
            <p>{{ section.paragraph }}</p>
          </section>
        </section>
      </div>

      <img class="logo-bottom" src="@/assets/images/result-pdf/logos/logo_gilde.png" alt="logo Gilde Opleidingen">
    </article>
  </PDFGenerator>
</template>

<script>
import PDFGenerator from '@/components/common/PDFGenerator.vue';

export default {
  components: {
    PDFGenerator
  },
  data() {
    return {
      fontFaces: [
        {
          family: 'NovaRound',
          src: require('@/assets/fonts/NovaRound-Regular.ttf'),
          weight: 'normal'
        },
        {
          family: 'Nunito',
          src: require('@/assets/fonts/Nunito-Regular.ttf'),
          weight: 'normal'
        }
      ]
    }
  },
  computed: {
    result() {
      return this.$store.getters.result
    }
  },
  methods: {
    async download(fileName) {
      const pdfDocument = await this.$refs.pdfGenerator.generate();

      pdfDocument.deletePage(2); // Delete blank page at end
      pdfDocument.save(fileName); // Save PDF file
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/variables/colors' as *;
@use '~@/assets/styles/mixins/utils' as *;

h1 {
  font-size: 20pt;
  line-height: 1.35;
  color: $blue;
}

h2 {
  font-size: 16pt;
  line-height: 1.35;
  color: $blue;
  margin-bottom: 16px;
}

h3 {
  font-size: 12pt;
  margin-bottom: 8px;
  color: $blue;
}

.template {
  text-align: left;
  width: 210mm;
  height: 297mm;
  padding: 1.5cm 1.5cm 1.5cm;
  font-size: 11pt;
  line-height: 1.5;
  color: $black;
  background: $gray-1;
  position: relative;
}

.content-container {
  position: relative;
  padding: 1cm;
  background: $white;
  border-radius: 16px;
  height: 100%;
}

// Separate clipping container to make clipping work properly in Chrome
.background-image-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 75%;
  height: 50%;
  overflow: hidden;
}

.background-image {
  position: absolute;
  bottom: -40px;
  right: -40px;
  width: 100%;
  height: auto;
}

.title {
  margin-bottom: 1.5cm;
}

.text-highlight {
  color: $green;
}

.body-section {
  margin-bottom: 1cm;
}

.logo-top {
  width: 2.5cm;
  height: 2.5cm;
  margin-bottom: 1cm;
}

.logo-bottom {
  @include position-center('x');
  bottom: 0.4cm;
  height: 0.75cm;
  height: 0.5cm;
}
</style>
