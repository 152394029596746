<template>
  <div class="question-item">
    <header class="header">
      <span class="question-number h1">{{ questionIndex + 1 }}</span>

      <h2 class="title h1">{{ question.title }}</h2>

      <div class="option-titles">
        <span class="option-title" v-for="option in options" :key="option.value">
          {{ option.label }}
        </span>
      </div>
    </header>

    <QuestionSubItem
      v-for="(subitem, i) in question.subitems"
      :key="i"
      :options="options"
      :title="`${i + 1}. ${subitem.title}`"
      :value="answers[i]"
      @change="value => onSubItemChange(i, value)"
    />
  </div>
</template>

<script>
import QuestionSubItem from '@/components/questions/QuestionSubItem';
import options from '@/assets/data/question-options';

export default {
  name: 'QuestionItem',
  components: {
    QuestionSubItem
  },
  data() {
    return {
      options
    }
  },
  props: {
    questionIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    // Get question data for the question at the provided questionIndex
    question() {
      return this.$store.state.questions[this.questionIndex];
    },
    // Get answer data for the question at the provided questionIndex
    answers() {
      return this.$store.state.answers[this.questionIndex];
    }
  },
  methods: {
    onSubItemChange(subitemIndex, value) {
      this.$store.commit('setSubitemAnswer', {
        questionIndex: this.questionIndex,
        subitemIndex,
        answer: value
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables/colors' as *;
@use '@/assets/styles/variables/typography' as *;
@use '~@/assets/styles/mixins/media-queries' as media;

.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
}

.question-number {
  align-self: center;
  font-family: $font-family-title;
  font-size: 64px;
  line-height: 1;
  margin: 0 32px 0 16px;
  color: $pink;

  @include media.max('tablet') {
    margin: 0 16px 0 0;
  }
}

.title {
  margin: 0;
  max-width: 600px;
}

.option-titles {
  display: flex;
  margin-left: auto;

  @include media.max('tablet') {
    display: none;
  }
}

.option-title {
  text-align: center;
  width: 104px;
  padding: 0 8px;
  font-weight: bold;
}
</style>
