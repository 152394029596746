<template>
  <div class="survey">
    <main class="content-width-lg">
      <img
        src="@/assets/images/logo.svg"
        alt="Stagetoer logo"
        class="logo"
        width="160"
        height="160"
      >

      <header class="stepper-header">
        <StepperSteps
          :steps="stepperItems.length"
          :active-step="activeStep"
        />
      </header>

      <StepperItems
        :items="stepperItems"
        :active-step="activeStep"
      />

      <footer class="stepper-footer">
        <BaseButton
          class="stepper-button stepper-previous-button"
          icon-left="arrow-left"
          variant="text"
          @click="changeActiveStep(-1)"
          v-if="activeStep != 0"
        >
          vorige
        </BaseButton>

        <BaseButton
          class="stepper-button stepper-next-button"
          icon-right="arrow-right"
          @click="changeActiveStep(1)"
          v-if="activeStep != stepperItems.length - 1"
          :disabled="nextButtonDisabled"
        >
          volgende
        </BaseButton>
      </footer>
    </main>
  </div>
</template>

<script>
import StepperSteps from '@/components/stepper/StepperSteps';
import StepperItems from '@/components/stepper/StepperItems';
import QuestionItem from '@/components/questions/QuestionItem';
import BaseButton from '@/components/ui/BaseButton';
import { clamp } from '@/assets/scripts/utils';
import { mapState } from 'vuex'

export default {
  name: 'Survey',
  components: {
    StepperSteps,
    StepperItems,
    BaseButton,
  },
  computed: {
    ...mapState([
      'questions',
      'answers',
      'activeStep'
    ]),
    stepperItems() {
      return [
        // Add a step for each question in the store
        ...this.questions.map((_, i) => ({
          key: `question-${i}`,
          component: QuestionItem,
          attributes: {
            questionIndex: i
          }
        })),

        // Load result compnent asynchronously because it won't intially be visible
        {
          key: 'result',
          component: () => import('@/components/survey/SurveyResult')
        }
      ]
    },
    nextButtonDisabled() {
      const activeStepData = this.stepperItems[this.activeStep]

      // Check if current step is a question
      if (activeStepData.component.name === 'QuestionItem') {
        // Get data for the current question from store
        const questionIndex = activeStepData.attributes.questionIndex;
        const answers = this.answers[questionIndex];
        const question = this.questions[questionIndex];

        // Return true if there are less answers than subitems
        // Filter out empty answers from array before checking array lengths
        return answers.filter(answer => Boolean(answer)).length != question.subitems.length;
      }

      return false;
    }
  },
  methods: {
    changeActiveStep(direction) {
      // Get the sign of the direction to go 1 step forwards or back
      const directionSign = Math.sign(direction);

      // Clamp the new active step between 0 and the stepper length
      const newActiveStep = clamp(this.activeStep + directionSign, 0, this.stepperItems.length - 1);

      // Scroll to the top of the page after transitioning
      window.scrollTo(0, 0);

      // Store the new active step value in the store
      this.$store.commit('setActiveStep', newActiveStep);
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/mixins/media-queries' as media;

.logo {
  display: block;
  width: 160px;
  height: auto;
  margin: 0 auto 40px;

  @include media.max('tablet') {
    width: 80px;
    margin: 0 auto 16px;
  }
}

.stepper-header {
  margin-bottom: 32px;

  @include media.min('tablet') {
    padding: 0 8px; // Padding to align steps with question sub items
  }
}

.stepper-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 72px -12px 0 -12px;

  @include media.max('tablet') {
    margin-top: 24px;
  }
}

.stepper-button {
  margin: 0 12px;

  @include media.max('mobile-l') {
    width: 100%;
  }
}

.stepper-next-button {
  @include media.min('mobile-l') {
    margin-left: auto;
  }
}
</style>
